.stepThroughButtons {
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.createStepPDFNameInput {
    padding: 1px;
}

.confirmTagsStep {
    padding-bottom: 50px;
    text-align: left;
}