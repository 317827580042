.App-header {
    font-family: 'Helvetica', 'Arial', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 40px;
    color: black;
    padding-bottom: 50px;
}

.App-name {
    padding-top: 30px;
    font-family: 'Langdon';
    font-size: larger;
    color: navy;
    text-decoration: none;
    outline: none;
}

@media screen and (max-width: 1000px) {
    .App-name {
        font-size: 35px;
    }
}

@font-face {
    font-family: Langdon;
    src: url('./fonts/Langdon.otf');
}

.logoLinkLeft {
    text-align: left;
    outline: none;
}

.logoLinkRight {
    text-align: right;
    outline: none;
}

.imageLogo {
    width: 5rem;
    height: 5rem;
}