.advancedTopics {
    background-color: rgba(255, 0, 0, .1);
}

.extension1Topics {
    background-color: rgba(51, 170, 51, .1);
}

.extension2Topics {
    background-color: rgba(0, 16, 255, 0.1);
}

.treeSelectTags div {
    color: navy;
    text-align: start;
    padding-bottom: 10px;
    user-select: none;
}

.rc-tree .rc-tree-treenode {
    white-space: normal !important;
}

@media screen and (max-width: 1000px) {
    .rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
        width: min(80vw, 400px) !important;
    }
}

/* non standard course topics */
.non-course-descriptions.rc-tree-treenode.rc-tree-treenode-switcher-close{
    color: lightgrey;
}

.rc-tree-node-content-wrapper {
    height: auto !important;
}

.rc-tree-node-content-wrapper-close {
    height: auto !important;
}

.rc-tree-title {
    padding-left: 25px;
}

.reset-topics-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    background: #fff;
    backface-visibility: hidden;
    border-radius: 0.375rem;
    border-style: solid;
    border-width: 0.125rem;
    box-sizing: border-box;
    color: navy;
    cursor: pointer;
    display: inline-block;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.3;
    padding: 0.5rem 1rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform 0.2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.reset-topics-button:not(:disabled):hover {
    transform: scale(1.05);
}

.reset-topics-button:not(:disabled):hover:active {
    transform: scale(1.05) translateY(0.125rem);
}

.reset-topics-button:focus {
    outline: 0 solid transparent;
}

.reset-topics-button:focus:before {
    content: "";
    left: calc(-1 * 0.375rem);
    pointer-events: none;
    position: absolute;
    top: calc(-1 * 0.375rem);
    transition: border-radius;
    user-select: none;
}

.reset-topics-button:focus:not(:focus-visible) {
    outline: 0 solid transparent;
}

.reset-topics-button:focus:not(:focus-visible):before {
    border-width: 0;
}

.reset-topics-button:not(:disabled):active {
    transform: translateY(0.125rem);
}
