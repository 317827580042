body {
  margin: 0;
  color: navy;
  font-family: 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Helvetica', 'Arial', sans-serif;
}
