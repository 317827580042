.subject-selection {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
}

/* CSS */
.subject-link {
    width: 100%;
    background: #fff;
    backface-visibility: hidden;
    border-radius: 0.375rem;
    border-style: solid;
    border-width: 0.125rem;
    box-sizing: border-box;
    color: navy;
    border-color: black;
    cursor: pointer;
    display: inline-block;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.3;
    padding: 0.875rem 1.125rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform 0.2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.subject-link-2U {
    background-color: rgba(255, 0, 0, .1);
}

.subject-link-3U {
    background-color: rgba(51, 170, 51, .1);
}

.subject-link-4U {
    background-color: rgba(0, 16, 255, 0.1);
}

.subject-link:not(:disabled):hover {
    transform: scale(1.05);
}

.subject-link:not(:disabled):hover:active {
    transform: scale(1.05) translateY(0.125rem);
}

.subject-link:focus {
    outline: 0 solid transparent;
}

.subject-link:focus:before {
    content: "";
    left: calc(-1 * 0.375rem);
    pointer-events: none;
    position: absolute;
    top: calc(-1 * 0.375rem);
    transition: border-radius;
    user-select: none;
}

.subject-link:focus:not(:focus-visible) {
    outline: 0 solid transparent;
}

.subject-link:focus:not(:focus-visible):before {
    border-width: 0;
}

.subject-link:not(:disabled):active {
    transform: translateY(0.125rem);
}
