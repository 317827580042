.App {
  text-align: center;
}

.Body {
  background-color: white;
}

.paperInfo {
  color: black;
}

.topBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
}

.selectionBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 10vw;
}