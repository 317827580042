.searchBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    background: #fff;
    backface-visibility: hidden;
    border-radius: 0.375rem;
    border-style: solid;
    border-width: 0.125rem;
    box-sizing: border-box;
    color: navy;
    display: inline-block;
    letter-spacing: -0.01em;
    line-height: 0.2;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    transform: translateZ(0) scale(1);
    transition: transform 0.2s;
}