.mediaCards {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 50px;
	flex-wrap: wrap;
	height: auto;
	width: auto;
	padding: 2em;
}

.mediaCardText {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.cardActions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}